import React, { Fragment, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { colors, fontSize } from 'styles/cp';

export type Props = {
  value?: string | React.ComponentElement<any, any>;
  pos?: null | undefined | 'wide' | 'medium';
  idValue: string;
  defaultMessage: string;
  maskedAccount?: string;
  color?: string;
  bold?: boolean;
  fontSize?: string;
  regular?: boolean;
  borderBottom?: string;
  label?: ReactNode;
  margin?: string;
};

const DataRow: React.FC<Props> = (props) => {
  const {
    value,
    pos,
    idValue,
    defaultMessage,
    label,
    maskedAccount,
    color,
    bold,
    regular,
    borderBottom,
    margin,
  } = props;
  function getMargins() {
    if (value === 'Apple Pay') {
      return '6px 0px 0px 0px';
    }
    if (pos === 'wide') {
      return '20px 0px 16px 0px';
    } else if (pos === 'medium') {
      return '0 11px 11px 11px';
    } else {
      return '6px 0px';
    }
  }

  return (
    <Fragment>
      <li aria-label="data row item">
        <div className="space-between flex-row" style={{ gap: 15 }}>
          <span className="label left">
            <span className="borderBottom">
              <FormattedMessage id={idValue} defaultMessage={defaultMessage} />
            </span>
            {label}
          </span>
          <span
            className="label label-data right"
            data-cy="data-row-value-target"
            data-testid={`data-row-${idValue.toLocaleLowerCase()}`}
          >
            {value}
          </span>
        </div>
        {value === 'Apple Pay' && (
          <span className="flex-row ap right">
            <span>{maskedAccount}</span>
          </span>
        )}
      </li>
      {/*language=SCSS*/}
      <style jsx>{`
        .space-between {
          display: flex;
          justify-content: space-between;
        }

        .flex-row {
          flex-direction: row;
          margin: ${margin ?? getMargins()};

          .label {
            cursor: text;
            font-size: ${props.fontSize ? props.fontSize : fontSize.xs};
            color: ${color || colors.gray};
            display: flex;
            font-family: ${bold ? 'AvenirNextforINTUIT-Demi' : 'AvenirNextforINTUIT-Regular'};
            white-space: nowrap;
          }

          &.ap {
            display: flex;
            justify-content: flex-end;
            font-size: ${fontSize.xxs};
            color: ${colors.gray03};
            margin-top: 0;
          }

          .right {
            text-align: right;
            font-family: ${regular ? 'AvenirNextforINTUIT-Regular' : 'AvenirNextforINTUIT-Medium'};
            margin-left: 5px;
          }

          .left {
            text-align: left;
          }

          .borderBottom {
            border-bottom: ${borderBottom};
          }
        }

        li {
          display: block;
        }
      `}</style>
    </Fragment>
  );
};

export default DataRow;
